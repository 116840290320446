.group {
  position: relative;
  margin-bottom: 1.7rem;

  nb-select ~ label {
    top: -20px;
    left: 5px;
    font-size: 14px;
  }

  textarea ~ label,
  nb-select ~ label,
  ams-e-code-autocomplete ~ label,
  ams-products-autocomplete ~ label,
  input[type='text'] ~ label,
  input[type='number'] ~ label,
  input[type='password'] ~ label,
  input[type='email'] ~ label {
    top: 12px;
    transition: 0.2s ease all;
    left: 10px;
    opacity: 0.6;
    font-size: 0.75rem;
    pointer-events: none;
  }

  input[type='file'] ~ label {
    width: 100%;
  }

  textarea ~ label,
  nb-select ~ label,
  ams-e-code-autocomplete ~ label,
  ams-products-autocomplete ~ label,
  input[type='text'] ~ label,
  input[type='number'] ~ label,
  input[type='password'] ~ label,
  input[type='email'] ~ label,
  nb-select ~ label {
    word-spacing: 1px;
    font-weight: bolder;
    position: absolute;
  }

  input:focus {
    border-color: #ffa100 !important;
  }

  input.input-sm ~ label {
    top: 6px;
    font-size: 14px;
  }

  textarea:focus ~ label,
  textarea.used ~ label,
  nb-select:focus ~ label,
  nb-select.used ~ label,
  ams-e-code-autocomplete.used ~ label,
  ams-products-autocomplete.used ~ label,
  input:focus[type='text'] ~ label,
  input.used[type='text'] ~ label,
  input:focus[type='number'] ~ label,
  input.used[type='number'] ~ label,
  input:focus[type='password'] ~ label,
  input.used[type='password'] ~ label,
  input:focus[type='email'] ~ label,
  input.used[type='email'] ~ label {
    opacity: 1;
    top: -20px;
    left: 5px;
    font-size: 14px;
    font-weight: bolder;
  }

  ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }

  input:focus::-webkit-input-placeholder {
    opacity: 1;
  }
}
