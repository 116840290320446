@import 'themes';

.ngx-datatable-footer {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}

.ams-ngx-datatable {
  height: 600px;
}

.ngx-datatable.material {
  .datatable-header {
    .datatable-header-inner {
      .datatable-header-cell:not(:last-child) {
        border-right: 1px solid #d1d4d7;
      }
    }
  }

  &.expandable {
    nb-list-item {
      display: block;
      font-size: 14px;
      padding: 0.2rem 1rem;
    }

    [nbInput].size-tiny {
      max-width: calc(100% - 60px) !important;
      padding: 3px 5px;
      font-size: 14px;
      line-height: 14px;
      font-weight: normal;
    }

    .datatable-body {
      .datatable-row-detail {
        padding: 10px 0;

        nb-list {
          height: 100%;
          overflow-y: auto;
        }
      }
    }
  }

  .datatable-body {
    .datatable-body-row {
      &:not(:first-child) {
        .datatable-body-cell {
          border-top: 1px solid #d1d4d7;
        }
      }

      &:last-child {
        .datatable-body-cell {
          border-bottom: 1px solid #d1d4d7;
        }
      }

      .datatable-body-cell {
        &:not(:last-child) {
          border-right: 1px solid #d1d4d7;
        }
      }
    }
  }
}
