@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import 'bootstrap/scss/bootstrap';
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';
@import './scss/themes';
@import './scss/pace.theme';
@import './scss/floating-label';
@import './scss/data-table';
@import 'ngx-toastr/toastr-bs5-alert';

:root {
  font-size: 16px;
}

@include nb-install() {
  @include nb-theme-global();
  @include nb-auth-global();
  @include ngx-pace-theme();

  .force-text-color {
    color: nb-theme(text-basic-color) !important;
  }

  @include media-breakpoint-up(xl) {
    .modal-lg {
      max-width: 1100px;
    }
  }

  .error-message {
    color: #ff386a;
    margin-top: 4px;
    margin-left: 4px;
    font-weight: bolder;
    font-size: 0.7rem;
  }

  .rb-required label {
    .label:after {
      position: absolute;
      color: #ff386a;
      right: -10px;
      top: 0;
      content: '*';
    }

    &.label.w-100:after {
      position: static;
    }
  }

  .cdk-overlay-container {
    z-index: 1070 !important;
  }

  .custom-checkbox {
    display: inline-block !important;
    cursor: pointer;

    label {
      cursor: pointer;
    }
  }

  input.custom-control-input[type='checkbox']:checked ~ .custom-control-label::after {
    top: 0.1rem !important;
    left: -1.1rem !important;
  }

  nb-checkbox label {
    cursor: pointer;
  }

  .btn:not(.btn-disabled) {
    cursor: pointer !important;
  }

  nb-card.rb-no-margin {
    margin: 0;

    button.close {
      float: right;
      background: transparent;
      border: none;
      font-size: 19px;
    }
  }

  button.rb-margin {
    @include nb-ltr(margin, 0 0.75rem 0 0);
    @include nb-rtl(margin, 0 0 0 0.75rem);
  }

  .custom-control .custom-control-indicator {
    border-radius: 50%;
  }

  .ngx-datatable.bootstrap {
    .datatable-footer {
      background: nb-theme(background-basic-color-2);
      color: nb-theme(text-basic-color);
    }

    .datatable-pager li:not(.active) a {
      color: nb-theme(text-basic-color);
    }
  }

  .ngx-contextmenu {
    background: nb-theme(background-basic-color-2);

    .dropdown-menu {
      padding: 0.25rem 0;
      background: nb-theme(background-basic-color-2);
    }

    li {
      a {
        text-decoration: none;
        color: nb-theme(text-basic-color);
      }
    }

    .hasSubMenu {
      &:before {
        position: relative;
        right: -7px;
        font-size: 0.7rem;
      }
    }

    a:hover {
      background: nb-theme(background-basic-color-3);
    }
  }
}

.modal-xl {
  max-width: 1400px;
}

.overlay-container {
  .toast-container {
    position: fixed;
  }
}

nb-auth {
  nb-card-header,
  .navigation {
    display: none !important;
  }
}
